/* You can add global styles to this file, and also import other style files */

$body-bg: #F3F4FA;
$font-family-base: "Roboto", sans-serif;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600&display=swap");

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

/* Importing other files */
@import 'scss/ngb-datepicker';
@import 'scss/ng-select';
@import "video.js/dist/video-js.css";
@import "@ng-select/ng-select/themes/default.theme.css";

@import 'ngx-toastr/toastr-bs5-alert';

.tooltip-wide {
  --bs-tooltip-max-width: 460px;
}
