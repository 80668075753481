@import "node_modules/bootstrap/scss/bootstrap-utilities";


.ng-select.choose-location-dropdown {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #191D28;

  @include media-breakpoint-up(md) {
    font-size: 18px;
    line-height: 25px;
  }

  .ng-select-container {
    border-radius: 8px;
    border: 1px solid #5E5E5E;

    .ng-value-container {
      .ng-placeholder {
        color: rgba(25, 29, 40, 0.55) !important;
      }

      .ng-value {

      }
    }
  }

  .ng-clear-wrapper {
    display: none;
  }

  .ng-dropdown-panel.ng-select-bottom {
    border-radius: 0 0 8px 8px;
    border-top-color: rgba(94, 94, 94, 0.3);
  }

  .ng-dropdown-panel-items {
    border-radius: 0 0 8px 8px;
    border: 1px solid #5E5E5E;
    border-top: 0;

    .ng-option:first-child {
      border-radius: 0 0 8px 8px;
    }

    .ng-option:last-child {

    }

    .ng-option.ng-option-child {
      padding-left: 14px;
    }
  }
}
