$base-color: #354FA9 !important;
$disable-background-color: #eceeef !important;

// job placement dropdown
.multiselect-dropdown {
  position: relative;
  width: 100%;
  font-size: inherit;
  font-family: inherit;

  .dropdown-btn {
    height: auto !important;
    min-height: 56px !important;

    display: flex;
    border: 1px solid #DBDCE7 !important;
    width: 100%;
    padding: 16px 42px 16px 12px !important;
    margin-bottom: 0;
    font-weight: normal;
    line-height: 1.52857143;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 8px !important;

    .selected-item-container {
      display: flex;
      float: left;
      max-width: 93%;

      .selected-item {
        border: 1px solid $base-color;
        margin-right: 4px;
        margin-bottom: 4px;
        background: $base-color;
        padding: 0 5px;
        color: #fff;
        border-radius: 2px;
        float: left;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        a {
          text-decoration: none;
        }
      }
    }

    .selected-item:hover {
      box-shadow: 1px 1px #959595;
    }

    .dropdown-multiselect__caret {
      line-height: 16px;
      display: block;
      position: absolute;
      box-sizing: border-box;
      width: 40px;
      height: 38px;
      right: 1px;
      top: 10px !important;
      padding: 4px 8px;
      margin: 0;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      transition: transform 0.2s ease;
    }

    .dropdown-multiselect__caret:before {
      position: relative;
      right: 0;
      top: 65%;
      color: #999;
      margin-top: 4px;
      border-style: solid;
      border-width: 8px 8px 0 8px;
      border-color: #999999 transparent;
      content: "";
    }


    > span:not(.selected-item-container):last-child span:not(.dropdown-multiselect__caret):first-child {
      position: absolute !important;
      right: 0 !important;
    }

    .dropdown-multiselect--active .dropdown-multiselect__caret {
      transform: rotateZ(180deg);
    }
  }

  .disabled {
    & > span {
      background-color: $disable-background-color;
    }
  }
}

.dropdown-list {
  position: absolute;
  padding-top: 6px;
  width: 100%;
  z-index: 9999;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
  margin-top: 10px;
  box-shadow: 0 1px 5px #959595;

  ul {
    padding: 0;
    list-style: none;
    overflow: auto;
    margin: 0;
  }

  li {
    padding: 6px 10px;
    cursor: pointer;
    text-align: left;
  }

  .filter-textbox {
    border-bottom: 1px solid #ccc;
    position: relative;
    padding: 10px;

    input {
      border: 0;
      width: 100%;
      padding: 0 0 0 26px;
    }

    input:focus {
      outline: none;
    }
  }
}

.multiselect-item-checkbox:hover {
  background-color: #e4e3e3;
}

.multiselect-item-checkbox input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.multiselect-item-checkbox input[type='checkbox']:focus + div:before,
.multiselect-item-checkbox input[type='checkbox']:hover + div:before {
  border-color: $base-color;
  background-color: #f2f2f2;
}

.multiselect-item-checkbox input[type='checkbox']:active + div:before {
  transition-duration: 0s;
}

.multiselect-item-checkbox input[type='checkbox'] + div {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  margin: 0;
  color: #000;
}

.multiselect-item-checkbox input[type='checkbox'] + div:before {
  box-sizing: content-box;
  content: '';
  color: $base-color;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid $base-color;
  text-align: center;
  transition: all 0.4s ease;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
  box-sizing: content-box;
  content: '';
  background-color: $base-color;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:disabled + div:before {
  border-color: #cccccc;
}

.multiselect-item-checkbox
input[type='checkbox']:disabled:focus
+ div:before
.multiselect-item-checkbox
input[type='checkbox']:disabled:hover
+ div:before {
  background-color: inherit;
}

.multiselect-item-checkbox
input[type='checkbox']:disabled:checked
+ div:before {
  background-color: #cccccc;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
  content: '';
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:before {
  animation: borderscale 200ms ease-in;
  background: $base-color;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
  transform: rotate(-45deg) scale(1);
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px $base-color;
  }
}
